/* You can add global styles to this file, and also import other style files */
// for buyer
@import 'libs/theme/scss/global_mixin.scss';
@import 'libs/theme/scss/global_variables';
@import '~swiper/scss';
@import "~swiper/css/free-mode";
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';
@import "~swiper/css/thumbs";
@import "~swiper/css/autoplay";

/*====================
1. Custom container CSS Start
====================*/

.main-content {
  position: relative;
  @media (max-width: 767px) {
    padding-bottom: 80px;
  }
}

.container-fluid {
  @media only screen and (min-width: 1366px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*====================
1. Global CSS Start
====================*/
a {
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    color: $neutral_80;
    text-decoration: unset;
  }
}
/*====================
1. Line Height CSS Start
====================*/

.line-height-24 {
  line-height: 24px;
}

.line-height-150-p {
  line-height: 150%;
}

/*====================
1. Font Weights CSS Start
====================*/

//global fonts weights
.font-weight-medium {
  font-weight: 500;
}

/*====================
1. Text Color CSS Start
====================*/

.brand-primary {
  color: $brand-primary;
}

.primary_90 {
  color: $primary_90;
}

.neutral_60 {
  color: $neutral_60;
}

.brand-secondary {
  color: $brand-secondary;
}

.brand-black {
  color: $brand-black;
}

.neutral_80 {
  color: $neutral_80;
}

.error_color {
  color: $error;
}

.brand-white {
  color: $brand-white;
}

.success_color {
  color: $success;
}

.toggle-disable {
  color: $toggle-disable;
}

.warning_color {
  color: $warning;
}

.brand-terciary {
  color: $brand-terciary;
}

.neutral_40 {
  color: $neutral_40;
}

.secondary_10 {
  color: $secondary_10;
}


/*====================
1. Background color CSS Start
====================*/

.bg-brand-color {
  background-color: $secondary_20;
}

.bg-success-color {
  background-color: $success;
}

.bg-toggle-disable {
  background-color: $toggle-disable;
}

.bg-warning-color {
  background-color: $warning;
}

.bg-brand-terciary {
  background-color: $brand-terciary;
}

.bg-secondary_10 {
  background-color: $secondary_10;
}


// Global imp class

.h-100vh {
  height: 100vh;
}

.w-30 {
  width: 30%;
}

/*====================
1. Margin CSS Start
====================*/

.mb-56 {
  margin-bottom: 56px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-32 {
  margin-bottom: 32px;
}

/*====================
1. Padding CSS Start
====================*/

.pt-176 {
  padding-top: 176px;
}

.pt-108 {
  padding-top: 108px;
}

.pb-88 {
  padding-bottom: 88px;
}

.pb-82 {
  padding-bottom: 82px;
}

.py-82 {
  padding-bottom: 82px;
  padding-top: 82px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-72,
.py-72 {
  padding-bottom: 72px;
}

.pt-72,
.py-72 {
  padding-top: 72px;
}

.pt-76 {
  padding-top: 76px;
}

.pt-56 {
  padding-top: 56px;
}

.pt-40 {
  padding-top: 40px;
}

.p-32 {
  padding: 32px;
}

.p-60 {
  padding: 60px;
}

.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.p-16 {
  padding: 16px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.px-mt-0 {
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
}

/*====================
1. Border Radius CSS Start
====================*/

.border-radius-16 {
  border-radius: 16px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-bottom-2-w {
  border-bottom: 2px solid $brand-white;
}

.mx-auto-991 {
  @media only screen and (max-width: 991px) {
    margin: 0 auto;
  }
}
/*====================
1. Button CSS Start
====================*/

.btn {
  &:focus,
  .focus {
    outline: 0;
    box-shadow: none;
  }
}

.form-control {
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.fc-input {
  background: $brand-white;
  border-radius: 16px;
  border-color: $brand-white;
  font-weight: 500;
  font-size: 14px;
  color: $neutral_40;
  height: 48px;

  &:focus {
    border-color: $neutral_80;
  }
}

.full-screen-modal {
  margin: 0;
  max-width: none;
  background-color: $brand-white;
}

.modal-content {
  border: none;
}

.app-footer {
  ul {
    li {

      a {
        color: $brand-white;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;

        &:hover {
          color: $neutral_80;
        }

        @media only screen and (max-width: 1023px) {
          font-size: 14px;
        }
      }

      + li {
        margin-top: 25px;

        @media (max-width: 991px) {
          margin-top: 15px;
        }
      }
    }
  }

  .left-por {

    ul {
      li {
        margin-top: 25px;
        + li {
          margin-left: 37px;
        }
        @media (max-width: 991px) {
          margin-top: 15px;
        }
      }
    }

    p {
      margin-top: 25px;
      @media (max-width: 991px) {
        margin-top: 15px;
      }
    }

    img {
      @media only screen and (max-width: 1024px) {
        max-width: 160px;
      }
    }
  }

  .mobile-view-footer {
    filter: drop-shadow(0px 0px 32px rgba($brand-black, 0.1));
    background-color: $brand-white;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    z-index: 11111;

    ul {
      li {
        margin-bottom: 0;

        a {
          &:hover,
          &:focus {
            img {
              filter: invert(100%);
            }
          }

          &.active {
            img {
              filter: invert(100%);
            }
          }
        }
      }
    }
  }
}

.cus-row {
  margin-left: -8px;
  margin-right: -8px;

  .col-cus-space {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
}

.row--m-5 {
  margin-left: -8px;
  margin-right: -8px;

  .col--p-5 {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 10px;
  }
}

.cat-box {
  cursor: pointer;

  .img-cat-box {
    border-radius: 16px;
    overflow: hidden;
    border: 4px solid $brand-transparent;
    transition: 0.3s;
    height: 272px;
    display: flex;
    align-items: center;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
      margin: auto;
      width: 100%;
      height: 100%;
    }

    &.card-one-box {
      @media only screen and (min-width: 992px) {
        height: 408px;
      }
      @media only screen and (max-width: 575px) {
        height: 160px;
      }
    }
  }

  .btn-white {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 16px;
    transition: 0.3s;
    padding: 8px 32px;
    max-width: max-content;
    border-radius: 20px;
  }

  .btn-white {
    min-height: 40px;
    @media only screen and (max-width: 767px) {
      min-height: 24px;
      padding-left: 15px;
      padding-right: 15px;
      max-width: max-content;
    }
  }

  &:hover {
    .img-cat-box {
      border: 4px solid $brand-secondary;
    }

    .btn-white {
      background-color: $brand-secondary;
      color: $brand-white;

    }
  }
}

// Authenticate
.auth_body {
  position: relative;
  min-height: 100vh;
  background: $secondary_20;
  @media only screen and (min-width: 1200px) {
    height: 100vh;
  }

}

// Authenticate
// Swiper
.swiper {
  .swiper-pagination-bullet {
    background-color: $brand-white;
  }
}

// Swiper
// btn width

.btn-176 {
  max-width: 176px;
}

.btn-136 {
  max-width: 136px;
}

// btn width

// Category

.app-card-two {
  .card-two {
    .swiper {
      @media only screen and (min-width: 1700px) {
        height: 608px;
      }
      @media only screen and (max-width: 1024px) {
        height: 308px;
      }
      @media only screen and (max-width: 991px) {
        height: 408px;
      }
      @media only screen and (max-width: 767px) {
        height: 240px;
      }
    }
  }
}

// Category

// Filter

.app-filters {
  //position: sticky;
  //top: 20px;
}

.cdk-global-scrollblock {
  .cdk-overlay-container {
    @media only screen and (max-width: 767px) {
      z-index: 99999;
    }
  }
}

/*====================
1. According CSS Start
====================*/

.header {
  position: unset;
  left: unset;
  right: unset;
  top: unset;
}

// according
.according {
  background-color: $brand-white;

  .accordion-group {
    .card {
      .card-header {
        background-color: $brand-white;
      }
    }

    .header-desc {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 132%;
      color: $brand-black;
          @media (max-width: 576px) {
            font-size: 14px;
          }
      &:last-of-type {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:first-of-type {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
    }
  }
}


.mat-bottom-sheet-container {
  border-radius: 16px 16px 0 0;
  padding-bottom: 20px;
}

.app-product {
  .product-main-image {
    .swiper-pagination-fraction,
    .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      @media only screen and (min-width: 991px) {
        display: none;
      }
      @media only screen and (max-width: 991px) {
        top: 50%;
        transform: translateX(68%) translateY(-50%) rotate(-90deg);
      }
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
      margin: auto;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      @media only screen and (max-width: 991px) {
        border-radius: 0;
      }
    }
  }

  .product-main-image-thumb {
    .swiper-slide {
      cursor: pointer;

      img {
        object-fit: cover;
        margin: 0;
        width: 100%;
        opacity: .5;
        border: 2px solid $brand-transparent;
      }

      &.swiper-slide-thumb-active {
        img {
          opacity: 1;
          border-color: $brand-black;
        }
      }
    }
  }

  .popover {
    max-width: 507px;
    border: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    left: 49px;
    width: 100%;
    @media only screen and (max-width: 991px) {
      max-width: 383px;
      left: 16px;
      width: 100%;
    }

    .popover-body {
      padding: 23px;

      .list--view-select-p {
        margin-top: 2rem;

        ul {
          margin: 0 10px;
          justify-content: space-between;

          li {
            padding: 0 10px;
            margin: 0;
            margin-bottom: 2rem;
            width: 20%;
            text-align: center;

            .list-p-w {
              margin: 0 auto;
            }
          }
        }
      }
    }

    .arrow {
      display: none;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .app-product-recent {
    .row--m-5 {
      flex-wrap: unset;
      overflow-y: auto;
    }
  }
}


/*====================
1. Responsive CSS Start
====================*/

@media only screen and (max-width: 1400px) {
  .pt-md-156 {
    padding-top: 156px;
  }
  .pb-md-38 {
    padding-bottom: 38px;
  }
  .pt-md-36 {
    padding-top: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .mb-xs-22 {
    margin-bottom: 22px;
  }
  .mb-xs-40 {
    margin-bottom: 40px;
  }
  .mb-xs-22 {
    margin-bottom: 22px;
  }
  .mb-xs-20 {
    margin-bottom: 20px;
  }

  .pt-xs-80 {
    padding-top: 80px;
  }
  .pb-xs-20 {
    padding-bottom: 20px;
  }

  .py-xs-30 {
    padding-top: 30px;
  }

  .pt-xs-25 {
    padding-top: 25px;
  }

  .py-xs-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .px-m-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
